import Axios from 'axios';
import { message } from 'antd';
// import qs from 'qs'

/**
 * 根据环境变量区分接口的默认地址
 */
/* switch(process.env.NODE_ENV){
    case "production":
        axios.defaults.baseURL='';//生产环境
        break;
    case "test":
        axios.defaults.baseURL="";//测试url
        break;
    default:
        axios.defaults.baseURL="http://192.168.0.153:3000/";//开发环境下的URL
        break;

} */
let axios = Axios.create({
  timeout: 30000,
  baseURL: '/api',
  withCredentials: false,
});

/**
 * 设置超时时间和跨域是否允许携带凭证
 */
// axios.defaults.timeout = 10000;//超时时间
// axios.defaults.withCredentials = true;//允许跨域

/**
 * 设置请求传递数据的格式
 * x-www-form-urlencoded
 */
/* axios.defaults.headers['Content-Type']='application/x-www-form-urlencoded';
axios.defaults.transformRequest=data=>qs.stringify(data); */

/**
 * 请求拦截器
 * TOKEN校验（JWT）:接收服务器返回的token 存储到本地存储中，
 * 每一次向服务器发送请求，我们应该携带token
 */
axios.interceptors.request.use(
  (config) => {
    //携带token
    let token = config.headers.Authorization || localStorage.getItem('token');
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * 响应拦截器
 */

/* axios.defaults.validateStatus=status=>{
 //自定义响应成功的HTTP状态码
    return /^(2|3)\d{2}$/.test(status);

}; */
axios.interceptors.response.use(
  (response) => {
    let token = localStorage.getItem('token');
    token && (response.headers.Authorization = token);
    // console.log(response);
    message.destroy();
    return response; //返回响应的数据
  },
  (error) => {
    message.destroy();
    let { response } = error;
    if (response) {
      switch (response.status) {
        case 400:
          throw error;
        case 504:
          // window.location.href = '/error?errInfo=请检查网络是否连接';
          throw error;
        case 401:
          localStorage.clear();
          // window.location.href = '/error?errInfo=访问不合法';
          throw error;
        case 403:
          break; //服务器拒绝执行
        case 404:
          break;
        default:
          break;
      }
    } else {
      // window.location.href = '/error?errInfo=请检查网络是否连接';
      console.error(error);
      throw error; //服务器问题
    }
  },
);

export default axios;
