import I18N, { i18n } from './I18nUtils';

export function parseUrl(url) {
  return [...new URL(url).searchParams].reduce((cur, [key, value]) => ((cur[key] = value), cur), {});
}

export function isLogin() {
  const token = localStorage.getItem('token');
  return token !== null && token !== undefined;
}

export function getYMDHMS(time) {
  const DAY = 24 * 60 * 60 * 1000;
  const day = Math.floor(time / DAY);
  const HOUR = 60 * 60 * 1000;
  const hour = Math.floor((time - day * DAY) / HOUR);
  const MINUTE = 60 * 1000;
  const minute = Math.floor((time - day * DAY - hour * HOUR) / MINUTE);
  const SECOND = 1000;
  const second = Math.floor((time - day * DAY - hour * HOUR - minute * MINUTE) / SECOND);
  let str =
    day +
    i18n(I18N.DAY) +
    (hour < 10 ? '0' + hour : hour) +
    i18n(I18N.HOUR) +
    (minute < 10 ? '0' + minute : minute) +
    i18n(I18N.MINUTE) +
    (second < 10 ? '0' + second : second) +
    i18n(I18N.SECOND);
  return str;
}

export function getTextWidth(text, fontSize) {
  let canvas = document.createElement('canvas');
  canvas.style.display = 'none';
  document.body.appendChild(canvas);
  let _ctx = canvas.getContext('2d');
  _ctx.font = fontSize + 'px -apple-system';
  let width = _ctx.measureText(text).width;
  document.body.removeChild(canvas);
  return Math.round(width - width / 30);
}

/**
 * @desc 函数节流
 * @param func (function) 函数
 * @param wait (number) 延迟执行毫秒数
 * @param type  (number) 1 表时间戳版，2 表定时器版
 */
export function throttle(func, wait, type = 1) {
  let previous, timeout;

  if (type === 1) {
    previous = 0;
  }

  return function () {
    let context = this;
    let args = arguments;

    if (type === 1) {
      let now = Date.now();

      if (now - previous > wait) {
        func.apply(context, args);
        previous = now;
      }
    } else if (type === 2) {
      if (!timeout) {
        timeout = setTimeout(function () {
          timeout = null;
          func.apply(context, args);
        }, wait);
      }
    }
  };
}
