import React from 'react';

const UserContext = React.createContext({});

export const withUserContext = (Component) =>
  React.forwardRef((props, ref) => (
    <UserContext.Consumer>{(context) => <Component userContext={context} {...props} ref={ref} />}</UserContext.Consumer>
  ));

export default UserContext;
